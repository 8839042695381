import { CreateToastFnReturn } from '@chakra-ui/react';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import Highcharts, { PointOptionsObject } from 'highcharts';

export function DeltaTempChart(
	sensor: sensor,
	batiment: batiment,
	mesures: [number, number][] | [number, number, number][],
	index: [number, number][],
	plotbands: Highcharts.AxisPlotBandsOptions[],
	toast: CreateToastFnReturn = null,
	simpleMode: boolean = false
): Highcharts.Options {
	const valeurHaute: [number, number][] = mesures.map((mesure) => [mesure[0], mesure[1]]);
	const valeurBasse: [number, number][] = mesures.map((mesure) => [mesure[0], mesure[2]]);
	const deltaTemp: ([number, number] | [number, any])[] = mesures.map((mesure) => [
		mesure[0],
		Number.parseFloat((mesure[1] - mesure[2]).toFixed(2)),
	]);
	const indexDeltaTemp: [number, number][] = [];

	let currentLastVal = 0;
	deltaTemp.forEach((mesure, i) => {
		if (i === 0 && !Number.isNaN(deltaTemp[0][1])) {
			indexDeltaTemp.push(deltaTemp[0]);
			currentLastVal = deltaTemp[0][1];
		} else if (!Number.isNaN(mesure[1])) {
			indexDeltaTemp.push([mesure[0], currentLastVal + mesure[1]]);
			currentLastVal += mesure[1];
		}
	});

	return {
		chart: {
			marginLeft: 75,
			zooming: {
				type: 'x',
				mouseWheel: {
					enabled: true,
				},
			},
			marginRight: 75,
			/*events: {
                load: !simpleMode ? function () {
                    const chart = this;
                    let diviser = false;

                    // Bouton de réinitialisation du zoom
                    chart.renderer
                        .button("Reset zoom", 0, 0, function () {
                            chart.zoomOut();
                        })
                        .attr({ zIndex: 999 })
                        .align({ align: "right", x: -100, y: 90 })
                        .add();
                } : null,
            },*/
		},
		accessibility: {
			enabled: false,
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 910,
					},
					chartOptions: {
						title: {
							text: sensor.type_energie,
						},
					},
				},
			],
		},
		rangeSelector: {
			enabled: false,
		},
		navigator: {
			enabled: simpleMode ? false : true,
		},
		legend: {
			enabled: simpleMode ? false : true,
			itemStyle: {
				fontSize: '16px',
			},
		},
		tooltip: {
			valueDecimals: 2,
			xDateFormat: '%A %d %B %Y %H:%M:%S',
		},
		title: {
			text: `Température du réseau de chaleur ${batiment.nom_bat} - ${sensor.type_energie}`,
			y: 20,
			align: 'center',
			margin: 40,
			style: {
				fontSize: '20px',
			},
		},
		xAxis: {
			type: 'datetime',
			labels: {
				rotation: 0, // Désactive la rotation des étiquettes de l'axe X
				style: {
					fontSize: '10px', // Réduit la taille de police des étiquettes de l'axe X
				},
			},
			plotBands: plotbands,
		},
		yAxis: [
			{
				title: {
					text: `Température instantanée (${sensor.unite})`,
					align: 'high', // Aligne le titre de l'axe gauche en haut
					textAlign: 'left',
					y: -15, // Déplace le titre plus haut que le début du graphique
					rotation: 0, // Désactive la rotation du titre de l'axe gauche
					style: {
						color: '#628fc9', // Utilise la première couleur de la palette Highcharts,
						fontSize: '13px',
						fontWeight: 'bold',
						width: 250,
					},
				},
				labels: {
					format: `{value}`,
				},
				opposite: false,
				endOnTick: true, // Permet à l'axe y de s'ajuster en fonction des données
			},
			{
				title: {
					text: `Index delta température (${sensor.unite})`,
					align: 'high', // Aligne le titre de l'axe gauche en haut
					textAlign: 'left',
					y: -15, // Déplace le titre plus haut que le début du graphique
					rotation: 0, // Désactive la rotation du titre de l'axe gauche
					style: {
						color: 'purple', // Utilise la première couleur de la palette Highcharts,
						fontSize: '13px',
						fontWeight: 'bold',
						width: 250,
					},
				},
				labels: {
					format: `{value}`,
				},
				opposite: true,
				endOnTick: true, // Permet à l'axe y de s'ajuster en fonction des données
			},
		],
		boost: {
			enabled: true,
		},
		series: [
			{
				type: 'line',
				name: 'Température haute',
				data: valeurHaute as (number | [string | Date, number] | PointOptionsObject)[],
				tooltip: {
					valueSuffix: `  °C`,
				},
				color: 'red',
			},
			{
				type: 'line',
				name: 'Température basse',
				data: valeurBasse as (number | [string | Date, number] | PointOptionsObject)[],
				tooltip: {
					valueSuffix: ` °C`,
				},
				color: 'blue',
			},
			{
				type: 'line',
				name: 'Delta de température',
				data: deltaTemp as (number | [string | Date, number] | PointOptionsObject)[],
				tooltip: {
					valueSuffix: ` °C`,
				},
				color: 'green',
			},
			{
				type: 'line',
				name: 'Index delta de température',
				data: indexDeltaTemp as (number | [string | Date, number] | PointOptionsObject)[],
				tooltip: {
					valueSuffix: ` °C`,
				},
				color: 'purple',
				yAxis: 1,
			},
		],
	};
}
